import React from 'react'
import Helmet from 'react-helmet'

const HelmetScript = () => (
  <Helmet>
    <script
      src="https://js.adsrvr.org/up_loader.1.1.0.js"
      type="text/javascript"
    />
    <script type="text/javascript">
      {`
            if (typeof ttd_dom_ready === 'function' && typeof TTDUniversalPixelApi === 'function') {
              ttd_dom_ready( function() {
                var universalPixelApi = new TTDUniversalPixelApi();
                universalPixelApi.init("yzrdth4", ["rg74cyb"], "https://insight.adsrvr.org/track/up");
              });
            }
          `}
    </script>
  </Helmet>
)

export default HelmetScript
